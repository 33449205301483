<template>
    <b-overlay>
        <b-card>
            <div>
                <b-button class="float-left mb-1" variant="success" :href="`/api/v1/user/waiting_list/excel`">Izvozi Excel
                    naročil
                </b-button>
                <b-form-input type="search" v-model="search" placeholder="Išči..." size="sm" class="float-right col-sm-2 mb-1"/>
                <b-table striped responsive bordered hover :items="filtered" :fields="fields" :filter="search"
                         :per-page="perPage" :current-page="currentPage" filter-debounce="500">
                    <template slot="top-row" slot-scope="{ fields }">
                        <b-td v-for="field in fields" :key="field.key">
                            <b-form-input v-if="field.key != 'actions'" size="sm" v-model="filters[field.key]"
                                          :placeholder="field.label"/>
                        </b-td>
                    </template>
                    <template v-slot:cell(status)="row">
                        <b-badge v-if="row.item.status == 'V planu'" variant="success">{{ row.item.status }}</b-badge>
                        <b-badge v-else-if="row.item.status == 'Na čakalni listi'" variant="primary">{{ row.item.status }}</b-badge>
                        <b-badge v-else variant="danger">{{ row.item.status }}</b-badge>
                    </template>
                    <template v-slot:cell(selected)="row">
                        <b-check v-model="row.item.selected"></b-check>
                    </template>
                    <template #cell(actions)="row">
                        <div class="d-flex">
                            <b-button class="mr-1 float-left" @click="$refs.orderModal.open(row.item)" variant="success">
                                <fa icon="eye"/>
                            </b-button>
                            <b-button class="float-right" variant="primary" @click="selectedOrder = row.item; orderMoveModal = true">
                                <fa icon="play-circle"/>
                            </b-button>
                        </div>
                    </template>
                    <template v-slot:cell(date_due)="row">
                        <div v-if="new Date(row.item.date_due).toLocaleTimeString('sl') === '00:00:00'">{{ new Date(row.item.date_due).toLocaleDateString("sl") }}</div>
                        <div v-else>{{ new Date(row.item.date_due).toLocaleDateString("sl") + " " + new Date(row.item.date_due).toLocaleTimeString("sl") }}</div>
                    </template>
                </b-table>
                <b-row>
                    <b-col class="float-left">
                        <b-dropdown variant="outline" :text="'Na stran: ' + perPage" size="sm" class="btn-none">
                            <b-dropdown-item v-model="perPage" v-for="(item, key) in pageOptions" :key="key"
                                             @click="setPerPage(item)">
                                {{ item }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                    <b-col sm="7" md="6" class="float-right">
                        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"/>
                    </b-col>
                </b-row>
            </div>
        </b-card>

        <OrderModal ref="orderModal"/>

        <b-modal size="m" title="Premikanje naročila" v-model="orderMoveModal" centered hide-footer>
            <b-form-select :options="otherPlans" label="Plan za premik" size="sm" v-model="selectedOtherPlanRaw"
                           @change=" it => this.selectedOtherPlan = it.id"></b-form-select>
            <b-button class="float-left mt-1" variant="info" @click="removeFromWaitingList()">
                Premakni iz čakalne vrste
            </b-button>
            <b-button class="float-left mt-1 ml-1" variant="primary" @click="moveOrderToOtherPlan()">
                Premakni
            </b-button>
            <b-button class="float-right mt-1" variant="danger" @click="orderMoveModal = false">Prekliči</b-button>
        </b-modal>
    </b-overlay>
</template>

<script>
    import OrderModal from '@/views/Narocilo'
    import fuzzysort from 'fuzzysort'

    export default {
        components: {
            OrderModal
        },
        data() {
            return {
                showOnlyNotInPlans: true,
                selectedDate: new Date().toISOString().substr(0, 10),
                dateFormat: {'year': 'numeric', 'month': 'numeric', 'day': 'numeric'},
                selectedItem: {},
                all_orders: [],
                orders_not_in_plans: [],
                search: '',
                orderMoveModal: false,
                selectedOrder: {},
                selectedOtherPlan: '',
                selectedOtherPlanRaw: {},
                otherPlans: [],
                perPage: 10,
                pageOptions: [10, 20, 50, 100],
                currentPage: 1,
                selected: {},
                fields: [
                    {key: 'id', label: 'ID', sortable: true, class: 'text-center'},
                    {key: 'client', label: 'Stranka', sortable: true, class: 'text-center'},
                    {key: 'date_due', label: 'Zapadlost', sortable: true, class: 'text-center'},
                    {key: 'quantity', label: 'Količina', sortable: true, class: 'text-center'},
                    {key: 'status', label: 'Status', sortable: true, class: 'text-center'},
                    {key: 'material_type.id', label: 'Material šifra', sortable: true, class: 'text-center'},
                    {key: 'material_type.material', label: 'Material naziv', sortable: true, class: 'text-center'},
                    {key: 'actions', label: ''}
                ],
                filters: {
                    'id': '',
                    'client': '',
                    'date_added': '',
                    'date_due': '',
                    'quantity': '',
                    'status': '',
                    'material_type.id': '',
                    'material_type.material': ''
                }
            }
        },
        methods: {
            getOrders() {
                const thisIns = this
                this.$http.get('/api/v1/user/waiting_list/')
                    .then(function(response) {
                        thisIns.all_orders = response.data

                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! Koda: ${error.response.status}`)
                    })

                this.$http.get('/api/v1/user/plan/')
                    .then(function(response) {

                        thisIns.otherPlans = response.data.filter(plan => plan.status === 'odprt').map(plan => {
                            return {
                                value: plan,
                                text: new Date(plan.date_planned).toLocaleDateString('sl')
                            }
                        })
                        if (thisIns.otherPlans.length > 0) {
                            thisIns.selectedOtherPlanRaw = thisIns.otherPlans[0].value
                            thisIns.selectedOtherPlan = thisIns.otherPlans[0].value.id
                        }

                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })
            },
            moveOrderToOtherPlan() {

                const thisIns = this
                this.$http.post('/api/v1/user/transfer_order', {
                    order_id: this.selectedOrder.id,
                    plan_id: 'waiting_list',
                    new_plan_id: this.selectedOtherPlan
                })
                    .then(function() {

                        thisIns.orderMoveModal = false
                        thisIns.$printSuccess('Naročilo uspešno premaknjeno!')

                        thisIns.getOrders()
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })
            },
            setPerPage(item) {
                this.perPage = item
            },
            removeFromWaitingList() {
                const thisIns = this
                this.$http.delete(`/api/v1/user/waiting_list/${thisIns.selectedOrder.id}`)
                    .then(function() {
                      
                        thisIns.getOrders()
                        thisIns.orderMoveModal  = false

                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! Koda: ${error.response.status}`)
                    })
            },
            flatten(obj, prefix = [], current = {}) {
                if (typeof (obj) === 'object' && obj !== null) {
                    for (const key of Object.keys(obj)) {
                        this.flatten(obj[key], prefix.concat(key), current)
                    }
                } else {
                    current[prefix.join('.')] = obj
                }
                return current
            }
        },
        computed: {
            totalRows() {
                return this.filtered.length
            },
            filtered() {

                const filtered = this.all_orders.filter(item => {
                    return Object.keys(this.filters).every(key => String(this.flatten(item)[key]).toLowerCase().includes(this.filters[key].toLowerCase()) || fuzzysort.single(this.filters[key].toLowerCase(), String(this.flatten(item)[key]).toLowerCase()) !== null)
                })


                return filtered.length > 0 ? filtered : [
                    {
                        id: '',
                        client: '',
                        date_added: '',
                        date_due: '',
                        quantity: '',
                        status: ''
                    }
                ]
            }
        },
        created() {
            this.getOrders()
        }
    }
</script>

<style>

</style>
