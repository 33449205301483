<template>
    <b-modal title="Podrobnosti naročila" v-model="orderModalActive" size="lg" centered hide-footer>
        <b-card>
            <b-button variant="primary" class="mb-1" @click="downloadPDF()">Izvozi PDF narocila</b-button>
            <b-button variant="warning" class="ml-1 mb-1" @click="showMaterialChangeModal = true" v-if="buttonActive">
                Spremeni material
            </b-button>
            <table>
                <tr>
                    <th class="pb-50">ID</th>
                    <td class="pb-50 pl-1">{{ order.id }}</td>
                </tr>
                <tr>
                    <th class="pb-50">Stranka</th>
                    <td class="pb-50 pl-1">{{ order.client }}</td>
                </tr>
                <tr>
                    <th class="pb-50">Zapadlost</th>
                    <td class="pb-50 pl-1">
                        {{ new Date(order.date_due).toLocaleDateString('sl') + " " + new Date(order.date_due).toLocaleTimeString('sl') }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">Status</th>
                    <td class="pb-50 pl-1">{{ order.status }}</td>
                </tr>
                <tr>
                    <th class="pb-50">Material</th>
                    <td class="pb-50 pl-1">{{ order.material_type.material }}</td>
                </tr>
                <tr>
                    <th class="pb-50">Tržnik email</th>
                    <td class="pb-50 pl-1">{{ order.sales_man_email }}</td>
                </tr>
                <tr>
                    <th class="pb-50">Tržnik šifra</th>
                    <td class="pb-50 pl-1">{{ order.sales_man_code }}</td>
                </tr>
                <tr>
                    <th class="pb-50">Material šifra</th>
                    <td class="pb-50 pl-1">{{ order.material_type.id }}</td>
                </tr>
                <tr>
                    <th class="pb-50">Komentar za razrez</th>
                    <td class="pb-50 pl-1">{{ order.comment_for_razrez }}</td>
                </tr>
                <tr>
                    <th class="pb-50">Komentar od razreza</th>
                    <td class="pb-50 pl-1">{{ order.comment_from_razrez }}</td>
                </tr>
                <tr>
                    <th class="pb-50">Opomba</th>
                    <td class="pb-50 pl-1">{{ order.comment }}</td>
                </tr>
            </table>
        </b-card>

        <b-card>
            <b-card-title>Kosi</b-card-title>
            <b-form-input type="search" v-model="search" placeholder="Išči..." size="sm"
                          class="float-right col-sm-3 mb-1"/>
            <b-table striped responsive bordered hover :items="order.order_pieces" :fields="fields" :filter="search"
                     :per-page="perPage" :current-page="currentPage">
            </b-table>
            <b-row>
                <b-col class="float-left">
                    <b-dropdown variant="outline" :text="'Na stran: ' + perPage" size="sm" class="btn-none">
                        <b-dropdown-item v-model="perPage" v-for="(item, key) in pageOptions" :key="key"
                                         @click="setPerPage(item)">
                            {{ item }}
                        </b-dropdown-item>
                    </b-dropdown>
                </b-col>
                <b-col sm="7" md="6" class="float-right">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right"
                                  size="sm"/>
                </b-col>
            </b-row>
        </b-card>
        <b-card>
            <b-card-title>Dodatni material</b-card-title>
            <b-form-input type="search" v-model="search" placeholder="Išči..." size="sm"
                          class="float-right col-sm-3 mb-1"/>
            <b-table striped responsive bordered hover :items="order.additional_material" :fields="fields_additional_material" :filter="search_additional_material"
                     :per-page="perPage_additional_material" :current-page="currentPage_additional_material">
            </b-table>
            <b-row>
                <b-col class="float-left">
                    <b-dropdown variant="outline" :text="'Na stran: ' + perPage_additional_material" size="sm" class="btn-none">
                        <b-dropdown-item v-model="perPage_additional_material" v-for="(item, key) in pageOptions" :key="key"
                                         @click="setPerPage(item)">
                            {{ item }}
                        </b-dropdown-item>
                    </b-dropdown>
                </b-col>
                <b-col sm="7" md="6" class="float-right">
                    <b-pagination v-model="currentPage_additional_material" :total-rows="totalRows_additional_material" :per-page="perPage_additional_material" align="right"
                                  size="sm"/>
                </b-col>
            </b-row>
        </b-card>

        <b-modal size="lg" v-model="showMaterialChangeModal" title="Sprememba materiala na naročilu" centered
                 hide-footer no-close-on-backdrop>

            <v-select v-model="order.material_type" label="id" :options="materialOptions" class="mb-1"
                      :get-option-label="(option) => {return `${option.id} - ${option.material}`}"
                      :filter-by="customFilter"/>

            <b-button class="float-right mt-1" variant="success"
                      @click="changeMaterialWithClose(order.id, order.material_type.id)"
                      @>Shrani
            </b-button>
        </b-modal>

    </b-modal>
</template>

<script>
    import fuzzysort from 'fuzzysort'
    import vSelect from 'vue-select'

    export default {
        components: {
            vSelect
        },
        props: {
            buttonActive: Boolean,
            materialOptions: Array,
            savePlan: Function,
            changeMaterial: Function
        },
        data() {
            return {
                orderModalActive: false,
                showMaterialChangeModal: false,
                order: {material_type: {}},
                search: '',
                perPage: 10,
                pageOptions: [10, 20, 50, 100],
                currentPage: 1,
                totalRows: 1,
                selected: {},
                fields: [
                    {key: 'length', label: 'Dolžina', sortable: true, class: 'text-center'},
                    {key: 'quantity', label: 'Količina', sortable: true, class: 'text-center'}
                ],
                fields_additional_material: [
                    {key: 'quantity', label: 'Količina', sortable: true, class: 'text-center'},
                    {key: 'unit', label: 'Enota', sortable: true, class: 'text-center'},
                    {key: 'description', label: 'Opis', sortable: true, class: 'text-center'},
                    {key: 'description2', label: 'Opis - 2', sortable: true, class: 'text-center'}

                ],
                search_additional_material: '',
                perPage_additional_material: 10,
                pageOptions_additional_material: [10, 20, 50, 100],
                currentPage_additional_material: 1,
                totalRows_additional_material: 1,
                filters: {length: '', material_type: ''}
            }
        },
        methods: {
            open(item) {
                if (item !== null) {
                    this.order = item
                    this.orderModalActive = true
                }
            },
            setPerPage(item) {
                this.perPage = item
            },
            downloadPDF() {
                const thisIns = this
                this.$http.post('/api/v1/user/order_pdf', this.order, {responseType: 'blob'}).then(res => {
                    const blob = new Blob([res.data], {type: res.headers['content-type']})
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = `${thisIns.order.id}.pdf`
                    link.click()
                })

            },
            customFilter(option, label, search) {
                return fuzzysort.single(search.toLowerCase(), label.toLowerCase()) !== null
            },
            async changeMaterialWithClose(orderId, materialId) {
                await this.$props.changeMaterial(orderId, materialId)
                this.showMaterialChangeModal = false
            }
        }
    }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
